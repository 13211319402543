<template>
	<div class="jy_table">
		<el-table ref="Table" v-bind="propsObj" @select="select" @select-all="selectAll" @selection-change="selectionChange" @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave" @cell-click="cellClick" @cell-dblclick="cellDblclick" @row-click="rowClick" @row-contextmenu="rowContextmenu" @row-dblclick="rowDblclick" @header-click="headerClick" @header-contextmenu="headerContextmenu" @sort-change="sortChange" @filter-change="filterChangeC">
			<slot></slot>
			<template slot="append">
				<slot name="append"></slot>
			</template>
			<template slot="empty">
				<slot name="empty"></slot>
			</template>
		</el-table>
	</div>
</template>
<script>
export default {
	name: 'jy-table',
	props: {
		data: {
			type: Array,
			default: function() {
				return [];
			}
		},
		height: {
			type: [String, Number],
			default: undefined
		},
		maxHeight: {
			type: [String, Number],
			default: undefined
		},
		stripe: {
			type: Boolean,
			default: false
		},
		border: {
			type: Boolean,
			default: true
		},
		size: {
			type: String,
			default: undefined
		},
		fit: {
			type: Boolean,
			default: undefined
		},
		showHeader: {
			type: Boolean,
			default: undefined
		},
		rowClassName: {
			type: [String, Function],
			default: ''
		},
		rowStyle: {
			type: [Object, Function],
			default: undefined
		},
		cellClassName: {
			type: [String, Function],
			default: ''
		},

		cellStyle: {
			type: [Object, Function],
			default: undefined
		},
		headerCellClassName: {
			type: [String, Function],
			default: undefined
		},
		headerCellStyle: {
			type: [Object, Function],
			default: undefined
		},
		rowKey: {
			type: [String, Function],
			default: undefined
		},
		emptyText: {
			type: String,
			default: '暂无数据'
		},
		defaultExpandAll: {
			type: Boolean,
			default: undefined
		},
		expanRowKeys: {
			type: Array,
			default: undefined
		},
		defaultSort: {
			type: Object,
			default: undefined
		},
		tooltipEffect: {
			type: String,
			default: undefined
		},
		showSummary: {
			type: Boolean,
			default: undefined
		},
		sumText: {
			type: String,
			default: '合计'
		},
		summaryNethod: {
			type: Function,
			default: undefined
		},
		spanMethod: {
			type: Function,
			default: undefined
		},
		selectOnIndeterminate: {
			type: Boolean,
			default: undefined
		},
		indent: {
			type: Number,
			default: undefined
		},
		lazy: {
			type: Boolean,
			default: undefined
		},
		treeProps: {
			type: Object,
			default: undefined
		},
		summaryMethod: {
			type: Function,
			default: undefined
		}
	},
	computed: {
		propsObj: function() {
			let obj = {
				data: this.data,
				height: this.height,
				maxHeight: this.maxHeight,
				stripe: this.stripe,
				border: this.border,
				size: this.size,
				fit: this.fit,
				showHeader: this.showHeader,
				rowClassName: this.rowClassName,
				rowStyle: this.rowStyle,
				cellClassName: this.cellClassName,
				cellStyle: this.cellStyle,
				headerCellClassName: this.headerCellClassName,
				headerCellStyle: this.headerCellStyle,
				rowKey: this.rowKey,
				emptyText: this.emptyText,
				defaultExpandAll: this.defaultExpandAll,
				expanRowKeys: this.expanRowKeys,
				defaultSort: this.defaultSort,
				tooltipEffect: this.tooltipEffect,
				showSummary: this.showSummary,
				sumText: this.sumText,
				summaryNethod: this.summaryNethod,
				spanMethod: this.spanMethod,
				selectOnIndeterminate: this.selectOnIndeterminate,
				indent: this.indent,
				lazy: this.lazy,
				treeProps: this.treeProps,
				summaryMethod: this.summaryMethod
			};
			for (let key in obj) {
				if (obj[key] === undefined) {
					delete obj[key];
				}
			}
			return obj;
		},
	},
	methods: {
		//事件
		select(selection, row) {
			this.$emit('select', selection, row)
		},
		selectAll(selection) {
			this.$emit('select-all', selection)
		},
		selectionChange(selection) {
			this.$emit('selection-change', selection)
		},
		cellMouseEnter(row, column, cell, event) {
			this.$emit('cell-mouse-enter', row, column, cell, event)
		},
		cellMouseLeave(row, column, cell, event) {
			this.$emit('cell-mouse-leave', row, column, cell, event)
		},
		cellClick(row, column, cell, event) {
			this.$emit('cell-click', row, column, cell, event)
		},
		cellDblclick(row, column, cell, event) {
			this.$emit('cell-dblclick', row, column, cell, event)
		},
		rowClick(row, column, event) {
			this.$emit('row-click', row, column, event)
		},
		rowContextmenu(row, column, event) {
			this.$emit('row-contextmenu', row, column, event)
		},
		rowDblclick(row, column, event) {
			this.$emit('row-dblclick', row, column, event)
		},
		headerClick(column, event) {
			this.$emit('header-click', column, event)
		},
		headerContextmenu(column, event) {
			this.$emit('header-contextmenu', column, event)
		},
		sortChange(data) {
			this.$emit('sort-change', data)
		},
		filterChangeC(filters) {
			this.$emit('filter-change', filters)
		},
		//ref fun
		clearSelection() {
			this.$refs.Table.clearSelection();
		},
		toggleRowSelection(row, selected) {
			this.$refs.Table.toggleRowSelection(row, selected);
		},
		toggleAllSelection() {
			this.$refs.Table.toggleAllSelection();
		},
		toggleRowExpansion(row, expanded) {
			this.$refs.Table.toggleRowExpansion(row, expanded);
		},
		setCurrentRow(row) {
			this.$refs.Table.setCurrentRow(row);
		},
		clearSort() {
			this.$refs.Table.clearSort();
		},
		clearFilter(columnKey) {
			this.$refs.Table.clearFilter(columnKey);
		},
		doLayout() {
			this.$refs.Table.doLayout();
		},
		sort(prop, order) {
			this.$refs.Table.sort(prop, order);
		},
	},
}

</script>
<style lang="scss">
.jy_table {
	margin-top: 10px;
    /* .el-table {
        .el-table__fixed { // 左固定列
        height: auto !important;
        bottom: 16px; // 改为自动高度后，设置与父容器的底部距离，高度会动态改变，值可以设置比滚动条的高度稍微大一些
        }
        .el-table__fixed-right { // 右固定列
        height: auto !important;
        bottom: 16px; // 改为自动高度后，设置与父容器的底部距离，高度会动态改变，值可以设置比滚动条的高度稍微大一些
        }
    } */
}

</style>
