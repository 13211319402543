<template>
	<column label="操作" v-bind:width="String(width)" fixed="right" align="center" v-if="isShow && isShowB">
		<template slot-scope="scope">
			<div
				v-for="one in list"
				v-bind:key="one.name"
				class="button"
				v-on:click="buttonClick(one, scope.row)"
				v-show="setIsShow(one, scope.row)"
			>
				<i v-bind:class="one.icon" v-if="false"></i>
				<span v-text="one.name"></span>
			</div>
		</template>
	</column>
</template>
<script>
import column from "./table.column.vue";
export default {
	name: "jy-operate",
	props: {
		list: {
			type: Array,
			default() {
				return [
					{
						name: "",
						icon: "",
						fun: null,
						isShow: null
					}
				];
			}
		},
		width: {
			type: [Number, String],
			default: 220
		}
	},
	components: {
		column
	},
	data() {
		return {
			isShow: true
		};
	},
	activated() {
		this.isShow = false;
		setTimeout(() => {
			this.isShow = true;
		});
	},
	computed: {
		isShowB: function () {
			let boolean = true;
			let parentData = this.$parent.data;
			if (parentData.length) {
				boolean = parentData.some(row => {
					return this.list.some(one => {
						return this.setIsShow(one, row);
					});
				});
			}
			return boolean;
		}
	},
	methods: {
		buttonClick(one, row) {
			one.fun && one.fun(row, one);
		},
		setIsShow(one, row) {
			return one.isShow ? one.isShow(row) : true;
		}
	}
};
</script>
<style lang="scss" scoped="operate_table">
.button {
	color: #2a73ff;
	cursor: pointer;
	vertical-align: middle;
	display: inline-block;
	padding: 0 6px;

	i {
		font-size: 15px;
		vertical-align: middle;
	}

	span {
		vertical-align: middle;
		line-height: 15px;
		display: inline-block;
		padding-left: 4px;
		font-size: 15px;
	}
}
</style>
