<template>
	<div class="page_top_box" v-if="isShow">
		<div class="page_top">
			<div class="logo">
				<!-- <img :src="logoHead" v-if="logoHead"><img :src="logo" alt="logo" v-if="logo"> -->
				<img src="../../components/jyui/assets/crrc-logo.png" alt="" />
			</div>
			<div class="page_top_nav">
				<slot></slot>
			</div>
			<div class="right_msg">
				<div class="name">
					<div class="name_box">
						<slot name="rightText">
							<span v-text="rightText" v-on:click="setIsShowHandle"></span>
						</slot>
					</div>
					<i class="el-icon-switch-button" v-on:click="setIsShowHandle"></i>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "jy-top",
	props: {
		noTopRoute: {
			type: Array,
			default: function () {
				return ["login"];
			}
		},
		logoutUrl: {
			type: String,
			default: "/logout"
		},
		logo: {
			type: [Object, String],
			default: ""
		},
		logoHead: {
			type: [Object, String],
			default: ""
		},
		rightText: {
			type: String,
			default: "欢迎"
		}
	},
	data() {
		return {};
	},
	activated() {},
	computed: {
		isShow: function () {
			let route = this.$route;
			let b = true;
			let noTopRoute = this.noTopRoute;
			if (noTopRoute.includes(route.name)) {
				b = false;
			}
			return b;
		}
	},
	watch: {
		$route: {
			handler: function () {},
			immediate: true
		}
	},
	methods: {
		setIsShowHandle() {
			this.$confirm("是否退出登录", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					this.logout();
				})
				.catch(() => {
					console.log("取消退出登录");
				});
		},
		logout() {
			this.$emit("logout");
		},
		rightTextClick() {
			this.$emit("rightTextClick");
		}
	}
};
</script>

<style lang="scss" scoped="page_top">
.page_top_box {
	height: 60px;
	background: url("~@/assets/menu_bg.png") no-repeat;
	background-size: 100% 100%;
	.page_top {
		// height: 60px;
		display: flex;
		justify-content: space-between;
		// background: $basecolor;
		/** logo */
		.logo {
			padding: 0 20px;
			img {
				height: 60px;
				margin-top: 5px;
			}
		}
		/** page_top_nav */
		.page_top_nav {
			flex: 1;
			height: 60px;
			overflow-y: hidden;
			overflow-x: auto;
			&::-webkit-scrollbar {
				width: 6px;
				height: 6px;
			}

			&::-webkit-scrollbar-track {
				background-color: transparent;
				border-radius: 3px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 3px;
				background-color: #e0e0e0;
				cursor: pointer;
			}
			&::-webkit-scrollbar-corner {
				background-color: #e8e8e8;
			}
		}
		/** right_msg */
		.right_msg {
			padding: 0 20px;
			color: #fff;
			font-size: 20px;
			line-height: 60px;
			height: 60px;
			.name {
				cursor: pointer;
			}
		}
	}
}
</style>

<!-- <style lang="scss" scoped="page_top">
.page_top_box {
    height: 60px;

    .page_top {
        height: 60px;
        background: $basecolor;
        text-align: left;
        min-width: $minWidth;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 1000;

        .logo {
            margin: 0 20px;
            float: left;
            height: 60px;

            img {
                // width: 100%;
                height: 60px;
            }
        }

        .bar {
            ul {
                float: left;

                li {
                    display: inline-block;
                    line-height: 60px;
                    color: #fff;
                    padding: 0 20px;
                    letter-spacing: 0.7px;
                    font-size: 14px;
                    @extend .hand;

                    &:hover {
                        background: #27486f;
                    }

                    &.alive {
                        background: #2e5079;
                    }
                }
            }
        }

        .page_top_nav {
            float: left;
            height: 60px;

            &:after {
                content: '';
                display: block;
                clear: both;
            }
        }

        .right_msg {
            float: right;
            color: #fff;
            line-height: 60px;
            font-size: 20px;
            margin-right: 30px;

            .name {
                position: relative;
                vertical-align: middle;

                .name_box {
                    display: inline-block;
                }

                i,
                span {
                    @extend .hand;
                    margin-left: 6px;
                }
            }
        }
    }
}
</style> -->
