import axios from "axios";
import qs from "qs";
import { Message, MessageBox } from "element-ui";
import Util from "@/common/js/util.js";
// import Router from '@/router/';

var instance = axios.create({});
var instanceBase = axios.create({});

// instance.defaults.headers.common['Authorization'] = AUTH_TOKEN
instance.defaults.baseURL = window.config.baseUrl;

// 添加请求拦截器
axios.interceptors.request.use(
	function (config) {
		// 在发送请求之前做些什么
		// config.headers.token = Util.getStor('token');
		return config;
	},
	function (error) {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
axios.interceptors.response.use(
	function (response) {
		// 对响应数据做点什么
		return response;
	},
	function (error) {
		// 对响应错误做点什么
		return error;
	}
);

var isLoginOut = false;
const defaultContentType = "application/json";
const http = {
	/**
	 * option:{
	 *      type:'json' //数据类型
	 *      isExcel:'' //是否接收类型为 blod
	 *      isBlod:'' //是否接收类型为 blod
	 *      isRequestParam:true //参数以RequestParam形式发送
	 *      hasObject:false //是否有对象（只有isRequestParam为true时有用）
	 *      err:function(err){}
	 * }
	 *
	 *  **/

	post: function (url, data, option) {
		let type = (option && option.type) || "json";
		let isRequestParam = !option || option.isRequestParam === undefined ? true : option.isRequestParam;
		let hasObject = (option && option.hasObject) || false;
		return new Promise(function (resolve, reject) {
			if (!data) {
				data = {};
			}
			data = typeof type == "string" && type == "format" ? qs.stringify(data) : data;
			console.log(22222, data, type)
			if (!url) {
				const errorText = "请先在“/common/js/config.js”文件中添加这个请求";
				console.error(errorText);
				reject();
			}

			let contentType = "";
			switch (type) {
				case "json":
					contentType = "application/json";
					break;
				case "format":
					contentType = "application/x-www-form-urlencoded";
					break;
				default:
					contentType = type;
			}
			if (isRequestParam) {
				// if(typeof data === 'string') {
				// 	url += `?${data}`
				// } else{
					let propsList = data ? Object.keys(data) : [];
					if (!hasObject) {
						propsList = data ? Object.keys(data).filter(item => typeof data[item] != "object") : [];
					}
					// 如果有参数
					if (propsList.length) {
						url += "?";
					}
					propsList.forEach((val, key) => {
						if (!hasObject) {
							url += val + "=" + data[val] + `${key === propsList.length - 1 ? "" : "&"}`;
						} else {
							url +=
								val +
								"=" +
								(typeof data[val] == "object" ? encodeURI(JSON.stringify(data[val])) : data[val]) +
								`${key === propsList.length - 1 ? "" : "&"}`;
						}
					});
				// }
			}

			let httpOption = {
				method: "post",
				url,
				data,
				headers: {
					"content-type": contentType
				}
			};

			//导出
			if (option && (option.isExcel || option.isBlod)) {
				httpOption.responseType = "blob";
			}

			if (typeof type == "object") {
				httpOption = {
					...httpOption,
					...type,
					headers: {
						"content-type": defaultContentType
					}
				};
			}

			let token = Util.getStor("token");
			if (token) {
				httpOption.headers.token = token;
			}
			let axios = option?.baseType === "base" ? instanceBase : instance;
			axios(httpOption)
				.then(res => {
					// console.log('url success:',url, res);
					//统一处理数据
					//统一处理错误
					let data = res.data;
					if (data.code == "0") {
						if (typeof data.detail === "string") {
							data.detail = JSON.parse(data.detail);
							if (data.detail && typeof data.detail.data === "string") {
								data.detail.list = JSON.parse(data.detail.data);
							}
							// let consoleStartStr = '\n%c---------------requestStart---------- '+url+'%c ----------requestStart---------------'
							// console.log(consoleStartStr,'color:black','color:black')
							// console.log(data.detail)
							// let consoleEmdtStr = '%c---------------requestEnd---------- '+url+'%c ----------requestEnd---------------\n'
							// console.log(consoleEmdtStr,'color:black','color:black')
						}
						resolve(data);
						if (isLoginOut) {
							isLoginOut = false;
						}
					} else if (data.code == "401") {
						Util.removeStor("token");
						if (!isLoginOut) {
							isLoginOut = true;
							MessageBox({
								title: "提示",
								message: "登录超时，请重新登录",
								callback: () => {
									// Router.push({
									//     name: 'login'
									// });
									window.location.reload();
								}
							});
						}
					} else if (data.code === 200) {
						resolve(data.data);
					} else {
						if (option && (option.isExcel || option.isBlod)) {
							resolve(res);
						} else if (option && option.err) {
							option.err(data);
						} else {
							let message = data.error || data.message || data.msg;
							Message({
								message,
								type: "error"
							});
							reject(message);
						}
					}
				})
				.catch(error => {
					// console.log('url error:',url, error);
					reject(error);
				});
		});
	}
};

export default http;
