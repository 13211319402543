const config = {
	// baseUrl: process.env.NODE_ENV === 'develop' ? '/api' : '',
  baseUrl: '/ischedule',
	chartColor: [
		'#61a5ff',
		'#1fd0ad',
		'#ffc056',
		'#fc6f6f',
		'#43d0ff',
		'#4f81bd',
		'#c0504d',
		'#9bbb59',
		'#8064a2',
		'#4bacc6',
		'#f79646',
	],
};

window.config = config;
