<template>
	<div class="jy_query_com" :class="{ card_com: card }">
		<div class="form-content">
			<el-form :model="model" ref="queryForm">
				<div class="query-form">
					<slot></slot>
				</div>
			</el-form>
			<div class="search-btn">
				<slot name="search"></slot>
			</div>
		</div>
		<div class="ope-area" v-if="$slots.operate">
			<slot name="operate"></slot>
		</div>
	</div>
</template>
<script>
export default {
	name: "jy-query",
	props: {
		buttonSpan: {
			type: [Number, String],
			default: 6
		},
		labelWidth: {
			type: [Number, String],
			default: "90px"
		},
		model: {
			type: Object,
			default: function () {
				return {};
			}
		},
		card: {
			type: Boolean,
			default: false
		},
		canHidden: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		canHidden: {
			handler: function (val) {
				if (val) {
					this.showArrow = true;
					this.limitHeight = true;
					this.$nextTick(() => {
						this.setQueryItemsBoxStyle();
					});
				} else {
					this.limitHeight = false;
					this.queryItemsBoxStyle = {};
				}
			},
			immediate: true
		}
	},
	data() {
		return {
			showArrow: false,
			limitHeight: false,
			queryItemsBoxStyle: {}
		};
	},
	computed: {
		queryItemSpan: function () {
			let buttonSpan = Number(this.buttonSpan) || 6;
			return 24 - buttonSpan;
		},
		formLebelWidth: function () {
			let n = Number(this.labelWidth.replace("px", "")) || 80;
			return n + "px";
		}
	},
	methods: {
		resetFields() {
			this.$refs.queryForm.resetFields();
		},
		toggleLimitHeight(b) {
			if (typeof b == "boolean") {
				this.limitHeight = b;
			} else {
				this.limitHeight = !this.limitHeight;
			}
		},
		setQueryItemsBoxStyle() {
			let items = this.$slots.default;

			items = items.map(one => one.componentInstance).filter(one => one);

			let colSpans = items.map(one => one.colSpan);

			let rows = 1;
			let colSpanSum = 0;
			colSpans.forEach(one => {
				if (colSpanSum >= 24) {
					rows++;
					colSpanSum = 0;
				}
				colSpanSum += one;
			});

			let itemHeight = 50;
			items.forEach(one => {
				let h = $(one.$el).height();
				if (h < itemHeight) {
					itemHeight = h;
				}
			});

			if (rows > 1) {
				this.showArrow = true;
			} else {
				this.showArrow = false;
			}

			this.queryItemsBoxStyle = {
				height: itemHeight * rows + "px"
			};
		}
	}
};
</script>
<style lang="scss">
.jy_query_com {
	&.card_com {
		padding: 15px 15px 0;
		background: #fff;
		margin: 10px 20px;
		box-shadow: 0 0 6px 0 #cfcfcf;
		border-radius: 4px;
	}

	/* .jy_query_flex_box {
		display: flex;

		.jy_query_flex_left {
			.jy_query_flex_left_arrow {
				cursor: pointer;

				i {
					transition: transform 0.5s;
					font-size: 14px;

					&.up {
						transform: rotate(180deg);
					}
				}
			}
		}

		.jy_query_flex_right {
			flex: 1;
		}
	}

	.query_items_box {
		transition: height 0.5s;
		overflow: hidden;

		&.limit_height {
			height: 50px !important;
		}
	}

	.el-form-item {
		margin-bottom: 14px;
	}

	.button_form_item {
		text-align: right;

		* + .el-row {
			.el-button {
				margin-top: 14px;
			}
		}
	} */
	.form-content {
		display: flex;
		align-items: flex-start;
		padding-bottom: 16px;
		border-bottom: solid 1px #f3f4f9;
		margin-bottom: 24px;
		.search-btn {
			// margin-left: auto;
			display: flex;
			flex-wrap: nowrap;
		}
	}
	.query-form {
		display: flex;
		flex-wrap: wrap;
		// align-items: center;
		.el-form-item {
			display: flex;
			margin-bottom: 14px;
			margin-right: 20px;
		}
	}
	.ope-area {
		margin-bottom: 15px;
		display: flex;
	}
}
</style>
