<template>
	<div class="admin_nav" :class="mode">
		<div class="nav_box">
			<!-- :background-color="'transparent'" -->
			<!-- :text-color="TextColor"
				:active-text-color="activeTextColor" -->
			<el-menu :router="false" :default-active="this.$route.path" unique-opened :mode="mode" @select="handleSelect">
				<template v-for="one in menu">
					<el-submenu popper-class="o-menu-pop" :index="one.url" v-if="one.children && one.children.length" v-bind:key="one.id">
						<template slot="title">
							<i :class="one.icone" v-if="one.icone"></i>
							<span>{{ one.text }}</span>
						</template>
						<template v-for="item in one.children">
							<el-submenu :index="item.url" v-if="item.children && item.children.length" v-bind:key="item.id">
								<template slot="title">
									<i :class="item.icone" v-if="item.icone"></i>
									<span>{{ item.text }}</span>
								</template>
								<template v-for="k in item.children">
									<el-menu-item :index="k.url" :key="k.id">
										<i :class="k.icone" v-if="k.icone"></i>
										<span slot="title">{{ k.text }}</span>
									</el-menu-item>
								</template>
							</el-submenu>
							<el-menu-item :index="item.url" :key="item.id" v-else>
								<i :class="item.icone" v-if="item.icone"></i>
								<span slot="title">{{ item.text }}</span>
							</el-menu-item>
						</template>
					</el-submenu>
					<el-menu-item :index="one.url" :key="one.id" v-else-if="!one.blank">
						<i :class="one.icone" v-if="one.icone"></i>
						<span slot="title">{{ one.text }}</span>
					</el-menu-item>
					<a :href="one.obj.url" :key="one.id" target="_blank" class="nav_a" v-else>
						<el-menu-item :key="one.id">
							<i :class="one.icone" v-if="one.icone"></i>
							<span>{{ one.text }}</span>
						</el-menu-item>
					</a>
				</template>
			</el-menu>
		</div>
	</div>
</template>
<script>
export default {
	name: "jy-menu",
	props: {
		backgroundColor: {
			type: String,
			default: "#edf0f5"
		},
		activeTextColor: {
			type: String,
			default: "#05265f"
		},
		TextColor: {
			type: String,
			default: "#fff"
		},
		mode: {
			type: String,
			default: "vertical" //horizontal
		},
		menu: {
			type: Array,
			default: function () {
				return [];
			}
		}
	},
	data() {
		return {
			openBlackPath: ["bigScreen", "departRemind"]
		};
	},
	activated() {
		// this.getUserMenu()
	},
	watch: {},
	methods: {
		handleSelect(path) {
			const isBlank = this.openBlackPath.some(item => path.includes(item));
			if (isBlank) {
				const routeUrl = this.$router.resolve({
					path
				});
				window.open(routeUrl.href, "_blank");
			} else {
				this.$router.push(path);
			}
		}
	}
};
</script>
<style lang="scss" scoped="admin_nav">
@import "../config/variable.scss";

.admin_nav {
	&.vertical {
		height: 100%;
		// background-color: $menu_bg;
		overflow-y: scroll;
		margin-right: -20px;

		.nav_box {
			width: 100%;

			.el-menu {
				border: none;
				.is-alive {
					// background: rgb(67, 74, 80) !important;
				}
			}
		}
	}
	.nav_a {
		text-decoration: none;
	}
}
</style>
<style lang="scss">
@import "../config/variable.scss";
.admin_nav {
	/* &.vertical {
		.el-menu-item,
		.el-submenu__title {
			&.is-active {
				* {
					// color: $menu_active;
				}
			}

			* {
				color: #5b5b5b;
			}
		}

		.el-submenu.is-active:not(.is-opened) .el-submenu__title,
		.el-menu-item.is-active {
			// background: rgb(67, 74, 80) !important;
			position: relative;
			i {
				&.el-submenu__icon-arrow {
					// color: $menu_active;
					font-weight: bold;
				}
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 4px;
				// background: $menu_active;
			}
		}
	}
	&.horizontal {
		.el-submenu__title {
			&.is-active {
				* {
					color: #fff;
					// background: $menu_active;
				}
			}

			* {
				color: #fff;
				// background: #fff;
			}
		}

		.el-submenu.is-active:not(.is-opened) .el-submenu__title,
		.el-menu-item.is-active {
			background: #3e7bfa !important;
			position: relative;
			i {
				&.el-submenu__icon-arrow {
					// color: $menu_active;
					font-weight: bold;
				}
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 4px;
				// background: $menu_active;
			}
		}
	} */
	.el-menu--horizontal {
		background-color: transparent;
		color: #fff;
		display: flex;
		.el-menu-item {
			color: #fff;
			font-size: 18px;
			font-weight: 500;
			&.is-active {
				background: #3e7bfa;
				border-bottom: none;
			}
		}
		.el-submenu {
			&.is-active {
				background: #3e7bfa;
				border-bottom: none;
			}
			.el-submenu__title {
				font-size: 18px;
				font-weight: 500;
				color: #fff;
				border-bottom: none;
				i {
					color: #fff;
				}
			}
		}
	}

	/* .el-menu.el-menu--horizontal,
	.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
	.el-menu--horizontal > .el-submenu .el-submenu__title:hover,
	.el-menu--horizontal > .el-submenu.is-active .el-submenu__title:hover {
		background-color: transparent;
		color: #fff;
	} */
	.el-menu--horizontal > .el-menu-item:hover,
	// .el-menu--horizontal > .el-menu-item:focus,
	.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
		background-color: transparent;
		color: #fff;
	}
	.el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
	.el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
		color: #fff;
	}
	.el-menu--horizontal > .el-menu-item.is-active:hover,
	.el-menu--horizontal > .el-submenu.is-active .el-submenu__title:hover {
		background-color: #3e7bfa;
	}
}
.o-menu-pop {
	background-color: #fff;
	.el-menu .el-menu-item,
	.el-menu .el-submenu__title {
		color: #1c1c28;
		font-weight: 500;
		line-height: 24px;
		font-size: 16px;
		&:hover {
			background: #f0f6ff;
		}
	}
	.el-submenu {
		&.is-active {
			.el-submenu__title {
				background: #f0f6ff;
				color: #2a73ff !important;
			}
		}
	}
	.el-menu-item {
		&.is-active {
			background: #f0f6ff;
			color: #2a73ff !important;
		}
	}

	.el-menu-item:not(.is-disabled):hover,
	.el-menu-item:not(.is-disabled):focus {
		background: #f0f6ff;
	}
}
</style>
